export const environment = {
    production: true,
    name: 'demo',
    BACKEND : 'https://api-am-demo.standardc.io/',
    FRONTEND : 'https://am-demo.standardc.io/',
    //IDme Client-id
    client_id : '1423d2d2fa51301207a2375ac7b5d31e',
    idme_url : 'https://api.idmelabs.com',
    idme_scope: "identity",
    idme_redirect_uri : 'https://api-am-demo.standardc.io/user/idmeAuth',
    reCaptchaSiteKey : '6Le69pUUAAAAAA9kamHDw_xKWiEcJq3-3noH97Dl'
  };